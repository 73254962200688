
.custom-radio-group--spinner {
    height: 7px;
    background-color: #007bff;
    border-radius: 2px;
    margin: 13px 1px -13px;
    -webkit-transition: width 1s;
    transition: width 1s;
}

.custom-radio-group--radio {
    display: none;
}

.custom-radio-group--button {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: whitesmoke;
    border: 1px solid #c4c4c4;
}

.custom-radio-group--button.selected {
    background-color: #007bff;
    border: 1px solid #005ab3;
}

.custom-radio-group--button:active,
.custom-radio-group--button:focus,
.custom-radio-group--button.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #007bff80;
    box-shadow: 0 0 0 0.2rem #007bff80
}

.custom-radio-group--wrapper
{
    display: flex;
    justify-content: space-between;
}

.custom-radio-group--spinner.disabled {
    background-color: #70b5ff;
}

.custom-radio-group--button.selected:disabled {
    opacity: unset;
    background-color: #70b5ff;
    border: 1px solid #3585d7;
}

.tooltip-box {
    position: relative;
    top: 5px;
    left: 0;
    width: 100%;
    max-width: none;
    z-index: 0;
}
