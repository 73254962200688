.centered-box {
    padding: 20px;
    text-align: center;
    width: 500px;
    margin: 50px auto;
}
.social-btn {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
}

.social-btn img {
    height: 24px;
    float: left;
}

.signup-link {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
}

.inline-block {
    display: inline-block;
}
.trend-y-axis {
    position: absolute;
    top: 130px;
    left: -67px;
    transform: rotate(-90deg);
    font-weight: bolder;
    font-size: 16px;
}
.trend-x-axis {
    position: absolute;
    bottom: 20px;
    left: 0;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    font-size: 14px;
}
