.user-preferences-form {
    margin-top: 20px;
}
.input-range {
    margin-top: 10px;
    margin-bottom: 25px;
}
.input-range__label-container {
    color: #333333;
}
.cron-builder-modal {
    max-width: 800px;
    min-width: 400px;
    width: 100%;
}
.checkbox {
    margin-right: 5px;
}
