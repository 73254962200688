
.profile-info-container {
    text-align: center;
    padding: 20px;
}

.profile-img {
    border-radius: 50%;
}

.profile-name {
    font-size: 20px;
}

.profile-screen-name, .profile-roles {
    font-size: 13px;
    color: slategray;
}
