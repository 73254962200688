.cron-builder-content {
    padding: 20px;
}

.radio-input-group-description {
    border: 1px solid #ced4da;
    flex: 1 1 auto;
    padding: 0 20px;
    border-radius: 0 .25rem .25rem 0;
}

.cron-builder-content input {
    margin-right: 5px;
}
