.centered-box {
    padding: 20px;
    text-align: center;
    width: 500px;
    margin: 50px auto;
}
.social-btn {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
}

.social-btn img {
    height: 24px;
    float: left;
}

.signup-link {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
}

.inline-block {
    display: inline-block;
}
.trend-y-axis {
    position: absolute;
    top: 130px;
    left: -67px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    font-weight: bolder;
    font-size: 16px;
}
.trend-x-axis {
    position: absolute;
    bottom: 20px;
    left: 0;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.login-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.65);
}

.signup-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.65);
}

.range-picker {
    margin: 10px 0 20px;
}

.page-title {
    font-size: 30px;
    margin-bottom: 20px;
}

.chart-title {
    font-weight: bolder;
    font-size: 23px;
}

.chart-subtitle {
    font-size: 17px;
}

.chart-increase {
    color: #27ae60 !important;
}

.chart-decrease {
    color: rgb(251, 85, 85) !important;
}

.color-preview {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin: 5px;
    border-radius: 2px;
}


.not-found > .desc {
    font-size: 20px;
    margin-bottom: 20px;
}

.go-back-btn {
    min-width: 160px;
}

.app-body {
    margin-top: 50px;
}
.user-preferences-form {
    margin-top: 20px;
}
.input-range {
    margin-top: 10px;
    margin-bottom: 25px;
}
.input-range__label-container {
    color: #333333;
}
.cron-builder-modal {
    max-width: 800px;
    min-width: 400px;
    width: 100%;
}
.checkbox {
    margin-right: 5px;
}


.profile-info-container {
    text-align: center;
    padding: 20px;
}

.profile-img {
    border-radius: 50%;
}

.profile-name {
    font-size: 20px;
}

.profile-screen-name, .profile-roles {
    font-size: 13px;
    color: slategray;
}

.cron-builder-content {
    padding: 20px;
}

.radio-input-group-description {
    border: 1px solid #ced4da;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 0 20px;
    border-radius: 0 .25rem .25rem 0;
}

.cron-builder-content input {
    margin-right: 5px;
}

/* DEFAULT COLOR VARIABLES */
/* DEFAULT SIZING VARIABLES */
/* DEFAULT CLASS VARIABLE */
/* STYLES */
.switch.switch--default > .switch-toggle {
    height: 32px;
    width: 52px;
    cursor: pointer !important;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    position: relative !important;
    display: inline-block; }
.switch.switch--default > .switch-toggle.switch-toggle--on::before, .switch.switch--default > .switch-toggle.switch-toggle--on::after, .switch.switch--default > .switch-toggle.switch-toggle--off::before, .switch.switch--default > .switch-toggle.switch-toggle--off::after {
    content: '';
    left: 0;
    position: absolute !important; }
.switch.switch--default > .switch-toggle.switch-toggle--on::before, .switch.switch--default > .switch-toggle.switch-toggle--off::before {
    height: inherit;
    width: inherit;
    border-radius: 16px;
    will-change: background;
    -webkit-transition: background .4s .3s ease-out;
    transition: background .4s .3s ease-out; }
.switch.switch--default > .switch-toggle.switch-toggle--on::after, .switch.switch--default > .switch-toggle.switch-toggle--off::after {
    top: 2px;
    height: 28px;
    width: 28px;
    border-radius: 14px;
    background: #ffffff !important;
    will-change: transform;
    -webkit-transition: -webkit-transform .4s ease-out;
    transition: -webkit-transform .4s ease-out;
    transition: transform .4s ease-out;
    transition: transform .4s ease-out, -webkit-transform .4s ease-out; }
.switch.switch--default > .switch-toggle.switch-toggle--on::before {
    background: #70b5ff !important; }
.switch.switch--default > .switch-toggle.switch-toggle--on::after {
    -webkit-transform: translateX(22px);
            transform: translateX(22px); }
.switch.switch--default > .switch-toggle.switch-toggle--off::before {
    background: #cccccc !important; }
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }

.switch.switch--graphite-small > .switch-toggle {
    height: 20px;
    width: 32.5px;
    cursor: pointer !important;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    position: relative !important;
    display: inline-block; }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
    content: '';
    left: 0;
    position: absolute !important; }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
    height: inherit;
    width: inherit;
    border-radius: 10px;
    will-change: background;
    -webkit-transition: background .4s .3s ease-out;
    transition: background .4s .3s ease-out; }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
    top: 2px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #ffffff !important;
    will-change: transform;
    -webkit-transition: -webkit-transform .4s ease-out;
    transition: -webkit-transform .4s ease-out;
    transition: transform .4s ease-out;
    transition: transform .4s ease-out, -webkit-transform .4s ease-out; }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before {
    background: gray !important; }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after {
    -webkit-transform: translateX(14.5px);
            transform: translateX(14.5px); }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
    background: #cccccc !important; }
.switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }


.custom-radio-group--spinner {
    height: 7px;
    background-color: #007bff;
    border-radius: 2px;
    margin: 13px 1px -13px;
    -webkit-transition: width 1s;
    transition: width 1s;
}

.custom-radio-group--radio {
    display: none;
}

.custom-radio-group--button {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: whitesmoke;
    border: 1px solid #c4c4c4;
}

.custom-radio-group--button.selected {
    background-color: #007bff;
    border: 1px solid #005ab3;
}

.custom-radio-group--button:active,
.custom-radio-group--button:focus,
.custom-radio-group--button.active {
    background-image: none;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff80
}

.custom-radio-group--wrapper
{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.custom-radio-group--spinner.disabled {
    background-color: #70b5ff;
}

.custom-radio-group--button.selected:disabled {
    opacity: unset;
    background-color: #70b5ff;
    border: 1px solid #3585d7;
}

.tooltip-box {
    position: relative;
    top: 5px;
    left: 0;
    width: 100%;
    max-width: none;
    z-index: 0;
}

