.chart-title {
    font-weight: bolder;
    font-size: 23px;
}

.chart-subtitle {
    font-size: 17px;
}

.chart-increase {
    color: #27ae60 !important;
}

.chart-decrease {
    color: rgb(251, 85, 85) !important;
}

.color-preview {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin: 5px;
    border-radius: 2px;
}
